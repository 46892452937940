import { Component, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

type StateT = {
  hasError: boolean;
};

export type ErrorBoundaryProps = {
  children: ReactNode;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, StateT> {
  public state = {
    hasError: false
  };

  public static getDerivedStateFromError(): { hasError: true } {
    return { hasError: true };
  }

  public componentDidCatch(): void {
    this.setState({ hasError: true });
  }

  public render(): ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <Navigate to="/500" /> : children;
  }
}

export default ErrorBoundary;
