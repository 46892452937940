import { FC, ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

type ContextValue = {
  open?: string;
  toggleDrawer: (key: string) => void;
};

const NavigationDrawerContext = createContext<ContextValue | undefined>(undefined);

type PropsT = { children: ReactNode };

export const NavigationDrawerProvider: FC<PropsT> = ({ children }) => {
  const [open, setOpen] = useState<string>();

  const toggleDrawer = useCallback(
    (key: string) => {
      if (key === open) {
        setOpen(undefined);
        document.body.style.overflowY = '';
        document.body.style.width = '';
      } else {
        document.body.style.overflowY = 'hidden';
        document.body.style.width = 'calc(100% - 17px)';
        setOpen(key);
      }
    },
    [open]
  );

  const value = useMemo(
    () => ({
      open,
      toggleDrawer
    }),
    [open, toggleDrawer]
  );

  return (
    <NavigationDrawerContext.Provider value={value}>{children}</NavigationDrawerContext.Provider>
  );
};

export const useNavigationDrawer = (): ContextValue => {
  const context = useContext(NavigationDrawerContext);

  if (!context) {
    throw new Error('useNavigationDrawer must be used within an NavigationDrawerContext');
  }

  return context;
};
