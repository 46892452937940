import { Collapse, CollapseProps } from 'antd';
import { useLanguages } from 'providers/LanguagesProvider';
import { FC, useCallback } from 'react';
import { ARROW_DONW_ICON } from 'utils/constants';
import { normalizeString } from 'utils/helpers';
import { ColumnChildrenT, NavigationTabT } from 'utils/types';
import NavigationDrawer from '../NavigationDrawer';
import styles from './NavigationTab.module.scss';

type PropsT = { tab: NavigationTabT };

const NavigationTab: FC<PropsT> = ({ tab: { columns, tabTitle, id } }) => {
  const { language } = useLanguages();

  const getItems = useCallback(
    (columns: ColumnChildrenT[]) => {
      const items: CollapseProps['items'] = columns.map(({ children, link, title }) => {
        if (!children) {
          return {
            key: normalizeString(title[language]),
            label: (
              <a href={link?.[language]} rel="noreferrer" className={styles.rootLevelLink}>
                {title[language]}
              </a>
            ),
            collapsible: 'disabled'
          };
        } else {
          return {
            key: normalizeString(title[language]),
            label: title[language],
            children: (
              <div className="d-flex flex-column">
                {children.map(({ link, title }) => (
                  <a
                    key={normalizeString(title[language])}
                    href={link[language]}
                    rel="noreferrer"
                    className={styles.nestedLevelLink}
                  >
                    {title[language]}
                  </a>
                ))}
              </div>
            )
          };
        }
      });

      return items;
    },
    [language]
  );

  return (
    <li className={`me-3 ${styles.listItem}`}>
      <NavigationDrawer id={id} buttonChildren={tabTitle[language]}>
        <div className="container">
          <div
            className={`d-flex justify-content-center align-items-start p-5 ${styles.columnList}`}
          >
            {!columns
              ? null
              : columns.map(({ children, columnTitle }, index) => (
                  <div className={`d-flex flex-column ${styles.tabColumn}`} key={index}>
                    <div className={styles.tabColumnTitle}>{columnTitle[language]}</div>

                    <Collapse
                      items={getItems(children)}
                      expandIconPosition="end"
                      accordion
                      rootClassName={styles.listItemAccordion}
                      expandIcon={({ isActive, collapsible }) => {
                        if (collapsible === 'disabled') {
                          return <></>;
                        }

                        return (
                          <img
                            width="12"
                            height="12"
                            src={ARROW_DONW_ICON}
                            alt="Accordion arrow"
                            className={`${isActive ? 'is-open' : ''} ${styles.listArrow}`}
                          />
                        );
                      }}
                    />
                  </div>
                ))}
          </div>
        </div>
      </NavigationDrawer>
    </li>
  );
};

export default NavigationTab;
