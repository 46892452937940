import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { isAvailableDomain } from 'utils/constants';
import de from './locales/de/translation.json';
import en from './locales/en/translation.json';
import fr from './locales/fr/translation.json';
import it from './locales/it/translation.json';
import nl from './locales/nl/translation.json';

export const resources = {
  en: {
    translation: en
  },
  nl: {
    translation: nl
  },
  fr: {
    translation: fr
  },
  de: {
    translation: de
  },
  it: {
    translation: it
  }
};

const getCurrentDomain = () => {
  const domain = window.location.hostname;

  if (domain === 'localhost') {
    return 'be';
  }

  const domainFromUrl = window.location.hostname.split('.').at(-1);

  return domainFromUrl && isAvailableDomain(domainFromUrl) ? domainFromUrl : 'be';
};

void i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: [
        'path',
        'localStorage',
        'sessionStorage',
        'cookie',
        'querystring',
        'htmlTag',
        'navigator'
      ]
    },
    resources,
    fallbackLng: getCurrentDomain() === 'be' ? 'nl' : 'fr',
    interpolation: {
      escapeValue: false
    }
  });
