import { useDomains } from 'providers/DomainProvider';
import { useLanguages } from 'providers/LanguagesProvider';
import { FC } from 'react';
import { FOOTER_NAVIGATION_COLUMNS } from 'utils/constants';
import { normalizeString } from 'utils/helpers';
import styles from './FooterNavigation.module.scss';

type PropsT = { className?: string };

const FooterNavigation: FC<PropsT> = ({ className = '' }) => {
  const { language } = useLanguages();
  const { currentDomain } = useDomains();

  return (
    <nav className={`${styles.footerNav} ${className}`}>
      {FOOTER_NAVIGATION_COLUMNS[currentDomain].map(({ children, columnTitle }, index) => (
        <div className={`d-flex flex-column ${styles.column}`} key={index}>
          <div className={styles.columnTitle}>{columnTitle[language]}</div>

          {children.map(({ link, title }) => (
            <div key={normalizeString(title[language])} className={styles.columnItem}>
              <a className={`d-block ${styles.itemLink}`} href={link?.[language]}>
                {title[language]}
              </a>
            </div>
          ))}
        </div>
      ))}
    </nav>
  );
};

export default FooterNavigation;
