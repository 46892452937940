import ErrorBoundary from 'components/ErrorBoundary';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { useDomains } from 'providers/DomainProvider';
import { useLanguages } from 'providers/LanguagesProvider';
import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { generateLanguage } from 'utils/helpers';
import styles from './MainPage.module.scss';

type RouteParams = {
  lang?: string;
  dealer?: string;
};

const MainPage: FC = () => {
  const { language, availableLanguages, changeLanguage } = useLanguages();
  const { defaultLanguage } = useDomains();
  const { lang, dealer } = useParams<RouteParams>();
  const location = useLocation();
  const navigate = useNavigate();

  const dealerId = dealer ? `volvo_${dealer}` : 'volvo_be';

  useEffect(() => {
    if (lang && language !== lang) {
      if (availableLanguages.includes(lang)) {
        void changeLanguage(lang);
      } else {
        void changeLanguage(defaultLanguage);
        navigate(generateLanguage(defaultLanguage, location));
      }
    }
  }, [availableLanguages, changeLanguage, defaultLanguage, lang, language, location, navigate]);

  return (
    <div className={styles.wrapper}>
      <ErrorBoundary>
        <Helmet>
          <link href="https://zubehoer-navigator.de/static/css/main.css" rel="stylesheet" />

          <script
            src="https://zubehoer-navigator.de/static/js/main.js"
            type="text/javascript"
            async
          />
        </Helmet>

        <Header />

        <div className="page-middle">
          <div
            key={dealer ?? 'volvo_be'}
            data-dealer-id={dealerId}
            data-environment="own"
            data-language={lang ?? language}
            data-role={dealer ? 'DEALER' : 'DISTRIBUTOR'}
            id="itt-sl-hpm"
          />
        </div>

        <Footer />
      </ErrorBoundary>
    </div>
  );
};

export default MainPage;
