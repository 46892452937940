import { compile, pathToRegexp } from 'path-to-regexp';
import { Location } from 'react-router-dom';

export const normalizeString = (s: string): string => s.replace(/\W/g, '').toLowerCase();

export const generateLanguage = (lang: string, location: Location): string => {
  const ROUTE = '/:lang/:path*';
  const definePath = compile(ROUTE);
  const routeComponents = pathToRegexp(ROUTE).exec(location.pathname);

  let subPaths: string[] | null = null;

  if (routeComponents && routeComponents[2]) {
    subPaths = routeComponents[2].split('/');
  }

  return definePath({
    lang: lang,
    path: subPaths
  });
};
