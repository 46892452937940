import { useDomains } from 'providers/DomainProvider';
import { NavigationDrawerProvider } from 'providers/NavigationDrawerProvider';
import { FC } from 'react';
import { NAVIGATION_TABS } from 'utils/constants';
import NavigationTab from '../NavigationTab';
import styles from './Navigation.module.scss';

type PropsT = { className?: string };

const Navigation: FC<PropsT> = ({ className = '' }) => {
  const { currentDomain } = useDomains();

  return (
    <nav className={`${styles.nav} ${className}`}>
      <NavigationDrawerProvider>
        <ul className="d-flex justify-content-center align-items-center list-inline mb-0">
          {NAVIGATION_TABS[currentDomain].map((tab) => (
            <NavigationTab key={tab.id} tab={tab} />
          ))}
        </ul>
      </NavigationDrawerProvider>
    </nav>
  );
};

export default Navigation;
