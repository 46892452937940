import { Root } from 'Root';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './styles/styles.scss';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <StrictMode>
      <Root />
    </StrictMode>
  );
}
