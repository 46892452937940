import { Button, Collapse, CollapseProps, Drawer } from 'antd';
import { useDomains } from 'providers/DomainProvider';
import { useLanguages } from 'providers/LanguagesProvider';
import { FC, useCallback, useState } from 'react';
import { ARROW_DONW_ICON, CLOSE_ICON, NAVIGATION_TABS } from 'utils/constants';
import { normalizeString } from 'utils/helpers';
import { NavigationTabT } from 'utils/types';
import Logo from '../Logo';
import styles from './MobileNavigation.module.scss';

type PropsT = { className?: string };

const MobileNavigation: FC<PropsT> = ({ className = '' }) => {
  const { t, language } = useLanguages();
  const { currentDomain } = useDomains();

  const [open, setOpen] = useState(false);

  const handleToggleDrawer = useCallback(() => {
    setOpen((state) => !state);
  }, []);

  const getItems = useCallback(
    (tabs: NavigationTabT[]) => {
      const items: CollapseProps['items'] = tabs.map(({ columns, tabTitle, id }) => ({
        key: id,
        label: tabTitle[language],
        children: columns?.map(({ children: rootChildren, columnTitle }, index) => (
          <div className="d-flex flex-column" key={index}>
            <div className={styles.columnTitle}>{columnTitle[language]}</div>

            <Collapse
              items={rootChildren?.map(({ children: nestChildren, link, title }) => {
                if (!nestChildren) {
                  return {
                    key: normalizeString(title[language]),
                    label: (
                      <a href={link?.[language]} rel="noreferrer" className={styles.rootLevelLink}>
                        {title[language]}
                      </a>
                    ),
                    collapsible: 'disabled'
                  };
                } else {
                  return {
                    key: normalizeString(title[language]),
                    label: title[language],
                    children: (
                      <div className="d-flex flex-column">
                        {nestChildren.map(({ link, title }) => (
                          <a
                            key={normalizeString(title[language])}
                            href={link[language]}
                            rel="noreferrer"
                            className={styles.nestedLevelLink}
                          >
                            {title[language]}
                          </a>
                        ))}
                      </div>
                    )
                  };
                }
              })}
              expandIconPosition="end"
              accordion
              rootClassName={`${styles.list} ${styles.list__nested}`}
              expandIcon={({ isActive, collapsible }) => {
                if (collapsible === 'disabled') {
                  return <></>;
                }

                return (
                  <img
                    width="16"
                    height="16"
                    src={ARROW_DONW_ICON}
                    alt="Accordion arrow"
                    className={`${isActive ? 'is-open' : ''} ${styles.listArrow}`}
                  />
                );
              }}
            />
          </div>
        ))
      }));

      return items;
    },
    [language]
  );

  return (
    <>
      <Button
        type="text"
        className={`${className} ${styles.menuToggleButton}`}
        onClick={handleToggleDrawer}
      >
        <span>{t('COMMON_HEADER__MOBILE_MENU_BUTTON')}</span>
      </Button>

      <Drawer
        placement="right"
        closable={false}
        onClose={handleToggleDrawer}
        open={open}
        title={
          <>
            <Logo className={styles.logo} />

            <Button type="text" onClick={handleToggleDrawer} className={styles.closeDrawerButton}>
              <img alt="drawer close button" src={CLOSE_ICON} height="24" width="24" />
            </Button>
          </>
        }
        rootClassName="d-block d-lg-none"
        className={styles.drawer}
      >
        <Collapse
          items={getItems(NAVIGATION_TABS[currentDomain])}
          expandIconPosition="end"
          accordion
          rootClassName={`${styles.list} ${styles.list__root}`}
          expandIcon={({ isActive, collapsible }) => {
            if (collapsible === 'disabled') {
              return <></>;
            }

            return (
              <img
                width="16"
                height="16"
                src={ARROW_DONW_ICON}
                alt="Accordion arrow"
                className={`${isActive ? 'is-open' : ''} ${styles.listArrow}`}
              />
            );
          }}
        />
      </Drawer>
    </>
  );
};

export default MobileNavigation;
