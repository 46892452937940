import { App } from 'App';
import { DomainsProvider } from 'providers/DomainProvider';
import { LanguagesProvider } from 'providers/LanguagesProvider';
import { FC } from 'react';
import './i18n/config';

export const Root: FC = () => (
  <DomainsProvider>
    <LanguagesProvider>
      <App />
    </LanguagesProvider>
  </DomainsProvider>
);
