import { Collapse, CollapseProps } from 'antd';
import { useDomains } from 'providers/DomainProvider';
import { useLanguages } from 'providers/LanguagesProvider';
import { FC, useCallback } from 'react';
import { ARROW_DONW_ICON, FOOTER_NAVIGATION_COLUMNS } from 'utils/constants';
import { normalizeString } from 'utils/helpers';
import { ColumnT } from 'utils/types';
import styles from './FooterMobileNavigation.module.scss';

type PropsT = { className?: string };

const FooterMobileNavigation: FC<PropsT> = ({ className = '' }) => {
  const { language } = useLanguages();
  const { currentDomain } = useDomains();

  const getItems = useCallback(
    (columns: ColumnT[]) => {
      const items: CollapseProps['items'] = columns.map(({ children, columnTitle }) => ({
        key: normalizeString(columnTitle[language]),
        label: columnTitle[language],
        children: (
          <div className={`d-flex flex-column ${styles.listInner}`}>
            {children?.map(({ title, link }) => (
              <div className="d-flex flex-column" key={normalizeString(title[language])}>
                <a href={link?.[language]} rel="noreferrer" className={styles.link}>
                  {title[language]}
                </a>
              </div>
            ))}
          </div>
        )
      }));

      return items;
    },
    [language]
  );

  return (
    <>
      <Collapse
        items={getItems(FOOTER_NAVIGATION_COLUMNS[currentDomain])}
        expandIconPosition="end"
        accordion
        rootClassName={`w-100 ${styles.list} ${className}`}
        expandIcon={({ isActive }) => (
          <img
            width="16"
            height="16"
            src={ARROW_DONW_ICON}
            alt="Accordion arrow"
            className={`${isActive ? 'is-open' : ''} ${styles.listArrow}`}
          />
        )}
      />
    </>
  );
};

export default FooterMobileNavigation;
