import { Circles, useLoading } from '@agney/react-loading';
import { FC } from 'react';

type LoadingPropsT = {
  fullWidth?: boolean;
};

const Loading: FC<LoadingPropsT> = ({ fullWidth }) => {
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Circles />
  });

  return <div {...containerProps}>{indicatorEl}</div>;
};

export default Loading;
