import { TFunction } from 'i18next';
import { FC, ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AVAILABLE_LANGUAGES } from 'utils/constants';
import { useDomains } from './DomainProvider';

type LanguagesContextState = {
  availableLanguages: string[];
  changeLanguage: (language: string, reload?: boolean) => Promise<void>;
  language: string;
  t: TFunction<'translation', undefined, 'translation'>;
};

const LanguagesContext = createContext<LanguagesContextState | undefined>(undefined);

type Props = { children?: ReactNode };

const LanguagesProvider: FC<Props> = ({ children }) => {
  const { t, i18n } = useTranslation();
  const { currentDomain, defaultLanguage } = useDomains();

  const [availableLanguages] = useState<string[]>(AVAILABLE_LANGUAGES[currentDomain]);

  const changeLanguage = useCallback(
    async (language: string, reload?: boolean) => {
      await i18n.changeLanguage(language);

      if (reload) {
        window.location.reload();
      }
    },
    [i18n]
  );

  const value = useMemo(
    () => ({
      availableLanguages,
      changeLanguage,
      language: availableLanguages.find((lang) => lang === i18n.language)
        ? i18n.language
        : defaultLanguage,
      t
    }),
    [availableLanguages, changeLanguage, defaultLanguage, i18n.language, t]
  );

  return <LanguagesContext.Provider value={value}>{children}</LanguagesContext.Provider>;
};

const useLanguages = (): LanguagesContextState => {
  const context = useContext(LanguagesContext);

  if (!context) {
    throw new Error('useLanguages must be used within an LanguagesContext');
  }

  return context;
};

export { LanguagesContext, LanguagesProvider, useLanguages };
