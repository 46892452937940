import { Button, FloatButton } from 'antd';
import { useDomains } from 'providers/DomainProvider';
import { useLanguages } from 'providers/LanguagesProvider';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  ARROW_UP_ICON,
  AVAILABLE_LANGUAGES_MAP,
  FOOTER_NAVIGATION_URLS,
  SOCIAL_NETWORKS
} from 'utils/constants';
import { generateLanguage } from 'utils/helpers';
import FooterMobileNavigation from '../FooterMobileNavigation';
import FooterNavigation from '../FooterNavigation';
import styles from './Footer.module.scss';

const Footer: FC = () => {
  const { availableLanguages, t, language, changeLanguage } = useLanguages();
  const { currentDomain } = useDomains();
  const location = useLocation();

  return (
    <footer className={`d-flex flex-column align-items-center p-4 ${styles.footer}`}>
      <FloatButton.BackTop
        rootClassName={`mb-5 ${styles.backTop}`}
        icon={null}
        shape="square"
        description={
          <>
            <span className="me-2">{t('COMMON_BACK_TO_TOP')}</span>

            <img loading="lazy" width="12" height="12" src={ARROW_UP_ICON} alt="On top" />
          </>
        }
      />

      <FooterNavigation className="d-none d-lg-flex" />

      <FooterMobileNavigation className="d-block d-lg-none" />

      {!!availableLanguages.length && (
        <div className={`pt-2 mx-4 d-flex mb-4 ${styles.languages}`}>
          {availableLanguages.map((lang) => (
            <Link
              key={lang}
              to={generateLanguage(lang, location)}
              className={language === lang ? 'is-active' : ''}
            >
              <Button type="text" key={lang} onClick={() => void changeLanguage(lang, true)}>
                {AVAILABLE_LANGUAGES_MAP[currentDomain][lang]}
              </Button>
            </Link>
          ))}
        </div>
      )}

      <ul className="d-flex align-items-center list-inline">
        {SOCIAL_NETWORKS.map(({ imageUrl, link, name }) => (
          <li key={name} className="mx-3">
            <a href={link[language]}>
              <img loading="lazy" width="24" height="24" src={imageUrl} alt={name} />
            </a>
          </li>
        ))}
      </ul>

      <div className="mt-3">
        <ul className="d-flex list-inline flex-wrap justify-content-center mb-0">
          {FOOTER_NAVIGATION_URLS[currentDomain].map(({ link, label }) => (
            <li key={link[language]}>
              <a
                href={link[language]}
                rel="noreferrer"
                className={`p-1 pb-0 m-2 mt-1 d-block ${styles.navLink}`}
              >
                {label[language]}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.copyright}>
        {t('COMMON_FOOTER__COPYRIGHT', { year: new Date().getFullYear() })}
      </div>
    </footer>
  );
};

export default Footer;
