import { useLanguages } from 'providers/LanguagesProvider';
import { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet';

type PropsT = {
  errorType: '500' | '404';
};

const ErrorPage: FC<PropsT> = ({ errorType = '500' }) => {
  const { t } = useLanguages();

  const title = useMemo(() => {
    switch (errorType) {
      case '404':
        return t('ERROR_PAGE__NOT_FOUND');

      default:
        return t('ERROR_PAGE__SOMETHING_WENT_WRONG');
    }
  }, [errorType, t]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="text-center my-4">
        <span>{title}</span>

        <div>{t('ERROR_PAGE__PLEASE_CONTACT_SUPPORT')}</div>
      </div>
    </>
  );
};

export default ErrorPage;
