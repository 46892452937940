import { FC } from 'react';
import { NAVIGATION_DRAWER_CONTAINER_CLASS } from 'utils/constants';
import Logo from '../Logo';
import MobileNavigation from '../MobileNavigation';
import Navigation from '../Navigation';
import styles from './Header.module.scss';

const Header: FC = () => {
  return (
    <>
      <header className={`${styles.header} pe-2`}>
        <Logo />

        <Navigation className="d-none d-lg-block" />

        <MobileNavigation className="d-block d-lg-none" />
      </header>

      <div className={NAVIGATION_DRAWER_CONTAINER_CLASS}></div>
    </>
  );
};

export default Header;
