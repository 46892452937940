import { FC, ReactNode, createContext, useContext, useMemo } from 'react';
import { AvailableDomains, isAvailableDomain } from 'utils/constants';

type DomainsContextState = {
  currentDomain: AvailableDomains;
  defaultLanguage: string;
};

const DomainsContext = createContext<DomainsContextState | undefined>(undefined);

type Props = { children?: ReactNode };

const DomainsProvider: FC<Props> = ({ children }) => {
  const currentDomain: AvailableDomains = useMemo(() => {
    const domain = window.location.hostname;

    if (domain === 'localhost') {
      // ? To change the domain zone in local development,
      // ? change the keys from 'be' to 'lu'
      return 'be';
    }

    const domainFromUrl: string | undefined = window.location.hostname.split('.').at(-1);

    return domainFromUrl && isAvailableDomain(domainFromUrl) ? domainFromUrl : 'be';
  }, []);
  const defaultLanguage = useMemo(() => {
    return currentDomain === 'be' ? 'nl' : 'fr';
  }, [currentDomain]);

  const value = useMemo(
    () => ({
      currentDomain,
      defaultLanguage
    }),
    [currentDomain, defaultLanguage]
  );

  return <DomainsContext.Provider value={value}>{children}</DomainsContext.Provider>;
};

const useDomains = (): DomainsContextState => {
  const context = useContext(DomainsContext);

  if (!context) {
    throw new Error('useDomains must be used within an DomainsContext');
  }

  return context;
};

export { DomainsContext, DomainsProvider, useDomains };
