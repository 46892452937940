import { FC } from 'react';
import styles from './Logo.module.scss';

type PropsT = { className?: string };

const Logo: FC<PropsT> = ({ className = '' }) => (
  <div className={`${styles.wrapper} ${className}`}>
    <a href="/">
      <img
        alt="Volvo Logo"
        height="10"
        src={process.env.PUBLIC_URL + '/images/volvo-wordmark-black.svg'}
      />
    </a>
  </div>
);

export default Logo;
