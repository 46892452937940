import { ColumnT, NavigationTabT } from './types';

export const NAVIGATION_DRAWER_CONTAINER_CLASS = 'navigation-drawer-container';

export type AvailableDomains = 'be' | 'lu';

export const isAvailableDomain = (domain: string): domain is AvailableDomains =>
  domain === 'be' || domain === 'lu';

export const AVAILABLE_LANGUAGES: Record<AvailableDomains, string[]> = {
  be: ['fr', 'nl'],
  lu: []
};

export const AVAILABLE_LANGUAGES_MAP: Record<string, Record<string, string>> = {
  be: {
    fr: 'Français',
    nl: 'Nederlands'
  },
  lu: {
    fr: 'Français'
  }
};

export const SOCIAL_NETWORKS = [
  {
    link: {
      nl: 'https://www.facebook.com/VolvoCarBeLux',
      fr: 'https://www.facebook.com/VolvoCarBeLux'
    },
    imageUrl:
      'https://www.volvocars.com/static/shared/icons/v2/thirdparty-facebook-24.svg#light-primary',
    name: 'Facebook'
  },
  {
    link: {
      nl: 'https://www.instagram.com/volvocarbelux',
      fr: 'https://www.instagram.com/volvocarbelux'
    },
    imageUrl:
      'https://www.volvocars.com/static/shared/icons/v2/thirdparty-instagram-24.svg#light-primary',
    name: 'Instagram'
  },
  {
    link: {
      nl: 'https://www.youtube.com/user/volvocarsbelux',
      fr: 'https://www.youtube.com/user/volvocarsbelux'
    },
    imageUrl:
      'https://www.volvocars.com/static/shared/icons/v2/thirdparty-youtube-24.svg#light-primary',
    name: 'YouTube'
  },
  {
    link: {
      nl: 'https://www.linkedin.com/company/volvo-car-belux?originalSubdomain=be',
      fr: 'https://www.linkedin.com/company/volvo-car-belux?originalSubdomain=be'
    },
    imageUrl:
      'https://www.volvocars.com/static/shared/icons/v2/thirdparty-linkedin-24.svg#light-primary',
    name: 'LinkedIn'
  }
];

export const FOOTER_NAVIGATION_URLS = {
  be: [
    {
      link: {
        nl: 'https://www.volvocars.com/nl-be/l/gdpr',
        fr: 'https://www.volvocars.com/fr-be/l/rgpd/'
      },
      label: {
        fr: `GDPR`,
        nl: 'GDPR'
      }
    },
    {
      link: {
        nl: 'https://www.volvocars.com/nl-be/v/legal/cookies',
        fr: 'https://www.volvocars.com/fr-be/v/legal/cookies'
      },
      label: {
        fr: `Cookies`,
        nl: 'Cookies'
      }
    },
    {
      link: {
        nl: 'https://www.volvocars.com/nl-be/legal/terms/terms-legal-notice',
        fr: 'https://www.volvocars.com/fr-be/v/legal/mentions-legales'
      },
      label: {
        fr: `Mentions légales`,
        nl: 'Juridische info'
      }
    },
    {
      link: {
        nl: 'https://www.volvocars.com/nl-be/legal/terms',
        fr: 'https://www.volvocars.com/fr-be/legal/terms'
      },
      label: {
        fr: `Documents juridiques`,
        nl: 'Juridische documenten'
      }
    },
    {
      link: {
        nl: 'https://www.volvocars.com/nl-be/legal/privacy/privacy-customer-privacy-policy',
        fr: 'https://www.volvocars.com/fr-be/legal/privacy/privacy-customer-privacy-policy'
      },
      label: {
        fr: `Politique de confidentialité`,
        nl: 'Privacybeleid'
      }
    },
    {
      link: {
        nl: 'https://www.volvocars.com/nl-be/legal/privacy/privacy-terms-business-information',
        fr: 'https://www.volvocars.com/fr-be/legal/privacy/privacy-terms-business-information'
      },
      label: {
        fr: `Informations d'entreprise`,
        nl: 'Bedrijfsinformatie'
      }
    }
  ],
  lu: [
    {
      link: {
        fr: 'https://www.volvocars.com/lu/l/rgpd/'
      },
      label: {
        fr: `GDPR`
      }
    },
    {
      link: {
        fr: 'https://www.volvocars.com/lu/v/legal/cookies'
      },
      label: {
        fr: `Cookies`
      }
    },
    {
      link: {
        fr: 'https://www.volvocars.com/lu/v/legal/mentions-legales'
      },
      label: {
        fr: `Mentions légales`
      }
    },
    {
      link: {
        fr: 'https://www.volvocars.com/lu/legal/terms/terms-legal-notice'
      },
      label: {
        fr: `Documents juridiques`
      }
    },
    {
      link: {
        fr: 'https://www.volvocars.com/lu/legal/privacy/privacy-customer-privacy-policy'
      },
      label: {
        fr: `Politique de confidentialité`
      }
    },
    {
      link: {
        fr: 'https://www.volvocars.com/lu/legal/privacy/privacy-terms-business-information'
      },
      label: {
        fr: `Informations d'entreprise`
      }
    }
  ]
};

export const NAVIGATION_TABS: Record<AvailableDomains, NavigationTabT[]> = {
  be: [
    {
      id: 'buying',
      tabTitle: {
        fr: 'Achat & Promotions',
        nl: 'Aankoop & Promoties'
      },
      columns: [
        {
          columnTitle: {
            fr: 'Acheter une Volvo',
            nl: 'Volvo kopen'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/build',
                nl: 'https://www.volvocars.com/nl-be/build'
              },
              title: {
                fr: 'Configurez',
                nl: 'Configureren'
              }
            },
            {
              link: {
                fr: 'https://www.volvostock.be/fr/vehicles/new',
                nl: 'https://www.volvostock.be/nl/vehicles/new'
              },
              title: {
                fr: 'Stock',
                nl: 'Stockwagens'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/l/volvo-selekt',
                nl: 'https://www.volvocars.com/nl-be/l/volvo-selekt'
              },
              title: {
                fr: 'Occasions',
                nl: 'Tweedehandswagens'
              }
            },
            {
              title: {
                fr: 'Promotions',
                nl: 'Promoties'
              },
              link: {
                nl: 'https://www.volvocars.com/nl-be/l/aanbiedingen/',
                fr: 'https://www.volvocars.com/fr-be/l/offres/'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/l/listesdeprix',
                nl: 'https://www.volvocars.com/nl-be/l/prijslijsten'
              },
              title: {
                fr: 'Listes de prix',
                nl: 'Prijslijsten'
              }
            }
          ]
        },
        {
          columnTitle: {
            fr: 'Financement & assurances',
            nl: 'Financieren & verzekeren'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/l/financement/business-use',
                nl: 'https://www.volvocars.com/nl-be/l/financiering/business-use'
              },
              title: {
                fr: 'Pour les professionnels',
                nl: 'Voor professionelen'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/l/financement/private-use',
                nl: 'https://www.volvocars.com/nl-be/l/financiering/private-use'
              },
              title: {
                fr: 'Pour les particuliers',
                nl: 'Voor particulieren'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/l/assurance-omnium/',
                nl: 'https://www.volvocars.com/nl-be/l/omniumverzekering/'
              },
              title: {
                fr: 'Assurance Omnium',
                nl: 'Omniumverzekeringen'
              }
            }
          ]
        },
        {
          columnTitle: {
            fr: 'Business',
            nl: 'Business'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/l/fleet',
                nl: 'https://www.volvocars.com/nl-be/l/fleet'
              },
              title: {
                fr: 'Véhicules de société & Flotte',
                nl: 'Bedrijfswagens & Fleet'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/l/gouvernement-et-services-d-urgence',
                nl: 'https://www.volvocars.com/nl-be/special-vehicles/'
              },
              title: {
                fr: `Véhicules d'intervention`,
                nl: 'Interventievoertuigen'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/special-vehicles/protection/',
                nl: 'https://www.volvocars.com/nl-be/special-vehicles/protection/'
              },
              title: {
                fr: `Voitures blindés`,
                nl: 'Gepantserde voertuigen'
              }
            },
            {
              title: {
                fr: 'Diplomatic & Special sales',
                nl: 'Diplomatic & Special sales'
              },
              children: [
                {
                  link: {
                    fr: 'https://diplomatic.volvocarbelux.be/',
                    nl: 'https://diplomatic.volvocarbelux.be/'
                  },
                  title: {
                    fr: 'Diplomatic Sales',
                    nl: 'Diplomatic Sales'
                  }
                },
                {
                  link: {
                    fr: 'https://www.volvocars.com/us/l/usmilitary/',
                    nl: 'https://www.volvocars.com/us/l/usmilitary/'
                  },
                  title: {
                    fr: 'US Military sales',
                    nl: 'US Military sales'
                  }
                }
              ]
            }
          ]
        },
        {
          columnTitle: {
            fr: 'Autres',
            nl: 'Overige'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/test-drive-booking',
                nl: 'https://www.volvocars.com/nl-be/test-drive-booking'
              },
              title: {
                fr: 'Réservez un essai',
                nl: 'Boek een testrit'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/quote',
                nl: 'https://www.volvocars.com/nl-be/quote'
              },
              title: {
                fr: 'Demandez une offre',
                nl: 'Vraag een offerte'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/dealers/distributeurs',
                nl: 'https://www.volvocars.com/nl-be/dealers/verdelers'
              },
              title: {
                fr: 'Trouvez un distributeur',
                nl: 'Vind een verdeler'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/l/accessoires',
                nl: 'https://www.volvocars.com/nl-be/l/accessoires'
              },
              title: {
                fr: 'Accessoires',
                nl: 'Accessoires'
              }
            }
          ]
        }
      ]
    },
    {
      id: 'account',
      tabTitle: {
        fr: 'Ma Volvo',
        nl: 'Mijn Volvo'
      },
      columns: [
        {
          columnTitle: {
            fr: 'Service',
            nl: 'Service'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/service-booking',
                nl: 'https://www.volvocars.com/nl-be/service-booking'
              },
              title: {
                fr: 'Réservez un entretien',
                nl: 'Boek een onderhoud'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/l/services/',
                nl: 'https://www.volvocars.com/nl-be/l/services/'
              },
              title: {
                fr: 'Entretien et réparations',
                nl: 'Service & onderhoud'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/l/services/warranty/',
                nl: 'https://www.volvocars.com/nl-be/l/services/warranty/'
              },
              title: {
                fr: 'Garantie',
                nl: 'Garantie'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/l/assurance-omnium',
                nl: 'https://www.volvocars.com/nl-be/l/omniumverzekering'
              },
              title: {
                fr: 'Assurance Omnium',
                nl: 'Omniumverzekeringen'
              }
            }
          ]
        },
        {
          columnTitle: {
            fr: 'Support',
            nl: 'Support'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/l/services/volvo-assistance/',
                nl: 'https://www.volvocars.com/nl-be/l/services/volvo-assistance/'
              },
              title: {
                fr: 'Service de dépannage 24/7',
                nl: '24/7 Pechverhelping'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/support',
                nl: 'https://www.volvocars.com/nl-be/support'
              },
              title: {
                fr: 'Centre de support',
                nl: 'Support center'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/support/contact',
                nl: 'https://www.volvocars.com/nl-be/support/contact'
              },
              title: {
                fr: 'Contact',
                nl: 'Contact'
              }
            }
          ]
        },
        {
          columnTitle: {
            fr: 'Compte Volvo',
            nl: 'Volvo Account'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/volvo-cars-app',
                nl: 'https://www.volvocars.com/nl-be/volvo-cars-app'
              },
              title: {
                fr: 'Application Volvo Cars',
                nl: 'Volvo Cars App'
              }
            },
            {
              link: {
                fr: 'https://volvoid.eu.volvocars.com/VolvoLogin/login',
                nl: 'https://volvoid.eu.volvocars.com/VolvoLogin/login'
              },
              title: {
                fr: 'S’identifier avec Volvo ID',
                nl: 'Aanmelden met Volvo ID'
              }
            }
          ]
        }
      ]
    },
    {
      id: 'about',
      tabTitle: {
        fr: 'À propos de nous',
        nl: 'Over ons'
      },
      columns: [
        {
          columnTitle: {
            fr: 'Conduite électrique',
            nl: 'Elektrisch rijden'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/cars/electrification/',
                nl: 'https://www.volvocars.com/nl-be/cars/electrification/'
              },
              title: {
                fr: 'Électrification',
                nl: 'Elektrificatie'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/l/conduite-electrique',
                nl: 'https://www.volvocars.com/nl-be/l/elektrisch-rijden'
              },
              title: {
                fr: 'Tout sur la conduite électrique',
                nl: 'Alles over elektrisch rijden'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/v/cars/electric-cars',
                nl: 'https://www.volvocars.com/nl-be/cars/electric-cars/'
              },
              title: {
                fr: 'Nos voitures électriques',
                nl: 'Elektrische wagens'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/v/cars/plug-in-hybrids',
                nl: 'https://www.volvocars.com/nl-be/cars/plug-in-hybrids/'
              },
              title: {
                fr: 'Nos voitures plug-in hybrides',
                nl: 'Plug-inhybridewagens'
              }
            }
          ]
        },
        {
          columnTitle: {
            fr: 'À propos de Volvo',
            nl: 'Over Volvo Cars'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/v/our-story',
                nl: 'https://www.volvocars.com/nl-be/v/our-story'
              },
              title: {
                fr: 'Nos valeurs',
                nl: 'Onze waarden'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/v/our-heritage',
                nl: 'https://www.volvocars.com/nl-be/v/our-heritage'
              },
              title: {
                fr: 'Notre héritage',
                nl: 'Ons erfgoed'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/v/safety/highlights',
                nl: 'https://www.volvocars.com/nl-be/v/safety/highlights'
              },
              title: {
                fr: 'Sécurité',
                nl: 'Veiligheid'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/v/sustainability/highlights',
                nl: 'https://www.volvocars.com/nl-be/v/sustainability/highlights'
              },
              title: {
                fr: 'Durabilité',
                nl: 'Duurzaamheid'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/v/connectivity/infotainment-page',
                nl: 'https://www.volvocars.com/nl-be/v/connectivity/infotainment-page'
              },
              title: {
                fr: 'Connectivité',
                nl: 'Connectiviteit'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/cars/family-cars/',
                nl: 'https://www.volvocars.com/nl-be/cars/family-cars/'
              },
              title: {
                fr: 'Volvo familiales',
                nl: `Gezinauto's`
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/cars/city-cars/',
                nl: 'https://www.volvocars.com/nl-be/cars/city-cars/'
              },
              title: {
                fr: 'Citadines',
                nl: `Stadsauto's`
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/cars/city-cars/',
                nl: 'https://www.volvocars.com/nl-be/cars/seven-seater/'
              },
              title: {
                fr: 'Voiture 7 places',
                nl: `Auto's met 7 zitplaatsen`
              }
            }
          ]
        },
        {
          columnTitle: {
            fr: 'Actualités',
            nl: 'Nieuws'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/news/latest',
                nl: 'https://www.volvocars.com/nl-be/news/latest'
              },
              title: {
                fr: 'Blog',
                nl: 'Blog'
              }
            },
            {
              link: {
                fr: 'https://investors.volvocars.com/en',
                nl: 'https://investors.volvocars.com'
              },
              title: {
                fr: 'Investisseurs',
                nl: 'Investeerders'
              }
            },

            {
              link: {
                fr: 'https://www.media.volvocars.com/be/fr-be',
                nl: 'https://www.media.volvocars.com/be/nl-be'
              },
              title: {
                fr: 'Média et presse',
                nl: 'Media & Pers'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/keep-me-updated',
                nl: 'https://www.volvocars.com/nl-be/keep-me-updated'
              },
              title: {
                fr: 'Newsletter',
                nl: 'Nieuwsbrief'
              }
            }
          ]
        }
      ]
    }
  ],
  lu: [
    {
      id: 'buying',
      tabTitle: {
        fr: 'Achat & Promotions'
      },
      columns: [
        {
          columnTitle: {
            fr: 'Acheter une Volvo'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/lu/build'
              },
              title: {
                fr: 'Configurez'
              }
            },
            {
              link: {
                fr: 'https://www.volvostock.be/fr/vehicles/new'
              },
              title: {
                fr: 'Stock'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/l/volvo-selekt'
              },
              title: {
                fr: 'Occasions'
              }
            },
            {
              title: {
                fr: 'Promotions'
              },
              link: {
                fr: 'https://www.volvocars.com/lu/l/offres/'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/l/listesdeprix'
              },
              title: {
                fr: 'Listes de prix'
              }
            }
          ]
        },
        {
          columnTitle: {
            fr: 'Financement & assurances'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/l/financement/business-use'
              },
              title: {
                fr: 'Pour les professionnels'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/fr-be/l/financement/private-use'
              },
              title: {
                fr: 'Pour les particuliers'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/l/assurance-omnium/'
              },
              title: {
                fr: 'Assurance Omnium'
              }
            }
          ]
        },
        {
          columnTitle: {
            fr: 'Business'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/lu/l/fleet'
              },
              title: {
                fr: 'Véhicules de société & Flotte'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/special-vehicles/'
              },
              title: {
                fr: `Véhicules d'intervention`
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/special-vehicles/protection/'
              },
              title: {
                fr: `Voitures blindés`
              }
            },
            {
              title: {
                fr: 'Diplomatic & Special sales'
              },
              children: [
                {
                  link: {
                    fr: 'https://diplomatic.volvocarbelux.be/'
                  },
                  title: {
                    fr: 'Diplomatic Sales'
                  }
                },
                {
                  link: {
                    fr: 'https://www.volvocars.com/us/l/usmilitary/'
                  },
                  title: {
                    fr: 'US Military sales'
                  }
                }
              ]
            }
          ]
        },
        {
          columnTitle: {
            fr: 'Autres'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/lu/test-drive-booking'
              },
              title: {
                fr: 'Réservez un essai'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/quote'
              },
              title: {
                fr: 'Demandez une offre'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/dealers/distributeurs'
              },
              title: {
                fr: 'Trouvez un distributeur'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/l/accessoires'
              },
              title: {
                fr: 'Accessoires'
              }
            }
          ]
        }
      ]
    },
    {
      id: 'account',
      tabTitle: {
        fr: 'Ma Volvo'
      },
      columns: [
        {
          columnTitle: {
            fr: 'Service'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/lu/service-booking'
              },
              title: {
                fr: 'Réservez un entretien'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/l/services/'
              },
              title: {
                fr: 'Entretien et réparations'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/l/services/warranty/'
              },
              title: {
                fr: 'Garantie'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/l/assurance-omnium'
              },
              title: {
                fr: 'Assurance Omnium'
              }
            }
          ]
        },
        {
          columnTitle: {
            fr: 'Support'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/lu/l/services/volvo-assistance/'
              },
              title: {
                fr: 'Service de dépannage 24/7'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/support'
              },
              title: {
                fr: 'Centre de support'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/support/contact'
              },
              title: {
                fr: 'Contact'
              }
            }
          ]
        },
        {
          columnTitle: {
            fr: 'Compte Volvo'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/lu/volvo-cars-app'
              },
              title: {
                fr: 'Application Volvo Cars'
              }
            },
            {
              link: {
                fr: 'https://volvoid.eu.volvocars.com/VolvoLogin/login'
              },
              title: {
                fr: 'S’identifier avec Volvo ID'
              }
            }
          ]
        }
      ]
    },
    {
      id: 'about',
      tabTitle: {
        fr: 'À propos de nous'
      },
      columns: [
        {
          columnTitle: {
            fr: 'Conduite électrique'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/lu/cars/electrification/'
              },
              title: {
                fr: `Centre d'électrification`
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/v/cars/electric-cars'
              },
              title: {
                fr: 'Nos voitures électriques'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/v/cars/plug-in-hybrids'
              },
              title: {
                fr: 'Nos voitures plug-in hybrides'
              }
            }
          ]
        },
        {
          columnTitle: {
            fr: 'À propos de Volvo'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/lu/v/our-story'
              },
              title: {
                fr: 'Nos valeurs'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/v/our-heritage'
              },
              title: {
                fr: 'Notre héritage'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/v/safety/highlights'
              },
              title: {
                fr: 'Sécurité'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/v/sustainability/highlights'
              },
              title: {
                fr: 'Durabilité'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/v/connectivity/infotainment-page'
              },
              title: {
                fr: 'Connectivité'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/cars/family-cars/'
              },
              title: {
                fr: 'Volvo familiales'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/cars/city-cars/'
              },
              title: {
                fr: 'Citadines'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/cars/city-cars/'
              },
              title: {
                fr: 'Voiture 7 places'
              }
            }
          ]
        },
        {
          columnTitle: {
            fr: 'Actualités'
          },
          children: [
            {
              link: {
                fr: 'https://www.volvocars.com/lu/news/latest'
              },
              title: {
                fr: 'Blog'
              }
            },
            {
              link: {
                fr: 'https://investors.volvocars.com/en'
              },
              title: {
                fr: 'Investisseurs'
              }
            },

            {
              link: {
                fr: 'https://www.media.volvocars.com/be/fr-be'
              },
              title: {
                fr: 'Média et presse'
              }
            },
            {
              link: {
                fr: 'https://www.volvocars.com/lu/keep-me-updated'
              },
              title: {
                fr: 'Newsletter'
              }
            }
          ]
        }
      ]
    }
  ]
};

export const FOOTER_NAVIGATION_COLUMNS: Record<AvailableDomains, ColumnT[]> = {
  be: [
    {
      columnTitle: {
        fr: 'Aide & support',
        nl: 'Help & support'
      },
      children: [
        {
          link: {
            fr: 'https://www.volvocars.com/fr-be/service-booking',
            nl: 'https://www.volvocars.com/nl-be/service-booking'
          },
          title: {
            fr: 'Réserver un entretien',
            nl: 'Boek een onderhoud'
          }
        },
        {
          link: {
            fr: 'https://www.volvocars.com/fr-be/l/services/',
            nl: 'https://www.volvocars.com/nl-be/l/services/'
          },
          title: {
            fr: 'Entretien et réparations',
            nl: 'Onderhoud & herstellingen'
          }
        },
        {
          link: {
            fr: 'https://www.volvocars.com/fr-be/support',
            nl: 'https://www.volvocars.com/nl-be/support'
          },
          title: {
            fr: 'Centre de support & manuels',
            nl: 'Support Center & Handleidingen'
          }
        }
      ]
    },
    {
      columnTitle: {
        fr: 'Contact',
        nl: 'Contact'
      },
      children: [
        {
          link: {
            fr: 'https://www.volvocars.com/fr-be/support/contact',
            nl: 'https://www.volvocars.com/nl-be/support/contact'
          },
          title: {
            fr: 'Contact',
            nl: 'Contacteer Volvo'
          }
        },
        {
          link: {
            fr: 'https://www.volvocars.com/fr-be/dealers/distributeurs',
            nl: 'https://www.volvocars.com/nl-be/dealers/verdelers'
          },
          title: {
            fr: 'Trouver un distributeur',
            nl: 'Vind een verdeler'
          }
        },
        {
          link: {
            fr: 'https://www.volvocars.com/fr-be/test-drive-booking',
            nl: 'https://www.volvocars.com/nl-be/test-drive-booking'
          },
          title: {
            fr: 'Réserver un essai',
            nl: 'Boek een testrit'
          }
        },
        {
          link: {
            fr: 'https://www.volvocars.com/fr-be/quote',
            nl: 'https://www.volvocars.com/nl-be/quote'
          },
          title: {
            fr: 'Demander une offre',
            nl: 'Vraag een offerte'
          }
        },
        {
          link: {
            fr: 'https://www.volvocars.com/intl/v/careers/highlights',
            nl: 'https://www.volvocars.com/intl/v/careers/highlights'
          },
          title: {
            fr: 'Emplois',
            nl: 'Jobs'
          }
        },
        {
          link: {
            fr: 'https://www.volvocars.com/fr-be/keep-me-updated?IB_eNews_B2C_VCBE',
            nl: 'https://www.volvocars.com/nl-be/keep-me-updated'
          },
          title: {
            fr: 'Newsletter',
            nl: 'Nieuwsbrief'
          }
        }
      ]
    },
    {
      columnTitle: {
        fr: 'Application volvo cars',
        nl: 'Volvo cars app'
      },
      children: [
        {
          link: {
            fr: 'https://apps.apple.com/be/app/volvo-cars/id439635293?l=fr',
            nl: 'https://apps.apple.com/be/app/volvo-cars/id439635293?l=nl'
          },
          title: {
            fr: 'App Store',
            nl: 'App Store'
          }
        },
        {
          link: {
            fr: 'https://play.google.com/store/apps/details?id=se.volvo.vcc&hl=fr&gl=BE',
            nl: 'https://play.google.com/store/apps/details?id=se.volvo.vcc&hl=nl&gl=BE'
          },
          title: {
            fr: 'Google Play',
            nl: 'Google Play'
          }
        }
      ]
    }
  ],
  lu: [
    {
      columnTitle: {
        fr: 'Aide & support'
      },
      children: [
        {
          link: {
            fr: 'https://www.volvocars.com/lu/service-booking'
          },
          title: {
            fr: 'Réserver un entretien'
          }
        },
        {
          link: {
            fr: 'https://www.volvocars.com/lu/l/services/'
          },
          title: {
            fr: 'Entretien et réparations'
          }
        },
        {
          link: {
            fr: 'https://www.volvocars.com/lu/support'
          },
          title: {
            fr: 'Centre de support & manuels'
          }
        }
      ]
    },
    {
      columnTitle: {
        fr: 'Contact'
      },
      children: [
        {
          link: {
            fr: 'https://www.volvocars.com/lu/support/contact'
          },
          title: {
            fr: 'Contact'
          }
        },
        {
          link: {
            fr: 'https://www.volvocars.com/lu/dealers/distributeurs'
          },
          title: {
            fr: 'Trouver un distributeur'
          }
        },
        {
          link: {
            fr: 'https://www.volvocars.com/lu/test-drive-booking'
          },
          title: {
            fr: 'Réserver un essai'
          }
        },
        {
          link: {
            fr: 'https://www.volvocars.com/lu/quote'
          },
          title: {
            fr: 'Demander une offre'
          }
        },
        {
          link: {
            fr: 'https://www.volvocars.com/intl/v/careers/highlights'
          },
          title: {
            fr: 'Emplois'
          }
        },
        {
          link: {
            fr: 'https://www.volvocars.com/lu/keep-me-updated'
          },
          title: {
            fr: 'Newsletter'
          }
        }
      ]
    },
    {
      columnTitle: {
        fr: 'Application volvo cars'
      },
      children: [
        {
          link: {
            fr: 'https://apps.apple.com/be/app/volvo-cars/id439635293?l=fr'
          },
          title: {
            fr: 'App Store'
          }
        },
        {
          link: {
            fr: 'https://play.google.com/store/apps/details?id=se.volvo.vcc&hl=fr&gl=BE'
          },
          title: {
            fr: 'Google Play'
          }
        }
      ]
    }
  ]
};

export const ARROW_DONW_ICON =
  process.env.PUBLIC_URL + '/images/navigation-chevrondown-12.svg#light-primary';

export const ARROW_UP_ICON =
  process.env.PUBLIC_URL + '/images/navigation-chevronup-12.svg#light-primary';

export const CLOSE_ICON = process.env.PUBLIC_URL + '/images/navigation-close-24.svg#light-primary';
