import ErrorBoundary from 'components/ErrorBoundary';
import Loading from 'components/Loading';
import ErrorPage from 'pages/ErrorPage';
import MainPage from 'pages/MainPage';
import { useDomains } from 'providers/DomainProvider';
import { useLanguages } from 'providers/LanguagesProvider';
import { FC, Suspense, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

export const App: FC = () => {
  const { language } = useLanguages();
  const { defaultLanguage } = useDomains();

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-P7C5XXJ' });
  }, []);

  useEffect(() => {
    // OneTrust Cookies Consent Notice start for volvocars-accessoires.be
    const script = document.createElement('script');

    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.setAttribute('data-domain-script', 'e3cf15e5-98d3-4ca0-9864-accc9562f90b');
    script.setAttribute('data-language', `${language}-be`);

    document.body.appendChild(script);

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    window['OptanonWrapper'] = function () {};

    return () => {
      document.body.removeChild(script);
    };
  }, [language]);

  return (
    <Suspense fallback={<Loading fullWidth={true} />}>
      <BrowserRouter>
        <ErrorBoundary>
          <Routes>
            <Route path="/:lang">
              <Route path="" element={<MainPage />} />

              <Route path=":dealer" element={<MainPage />} />
            </Route>

            <Route path="404" element={<ErrorPage errorType="404" />} />

            <Route path="500" element={<ErrorPage errorType="500" />} />

            <Route path="*" element={<Navigate to={`/${defaultLanguage}`} />} />
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>
    </Suspense>
  );
};
