import { Button, Drawer } from 'antd';
import { useNavigationDrawer } from 'providers/NavigationDrawerProvider';
import { FC, ReactNode, useCallback } from 'react';
import { CLOSE_ICON, NAVIGATION_DRAWER_CONTAINER_CLASS } from 'utils/constants';
import styles from './NavigationDrawer.module.scss';

type PropsT = {
  buttonChildren: ReactNode;
  children: ReactNode;
  id: string;
};

const NavigationDrawer: FC<PropsT> = ({ buttonChildren, children, id }) => {
  const { open, toggleDrawer } = useNavigationDrawer();

  const isActiveTab = open === id;

  const handleToggleDrawer = useCallback(() => {
    toggleDrawer(id);
  }, [id, toggleDrawer]);

  return (
    <>
      <Button
        type="text"
        onClick={handleToggleDrawer}
        className={`${styles.toggleTabButton} ${isActiveTab ? 'is-active' : ''} py-0 px-1`}
      >
        {buttonChildren}
      </Button>

      <Drawer
        placement="top"
        closable={false}
        onClose={handleToggleDrawer}
        open={isActiveTab}
        getContainer={`.${NAVIGATION_DRAWER_CONTAINER_CLASS}`}
        rootClassName="navigation-drawer d-none d-lg-block"
        maskClassName="navigation-drawer__mask"
      >
        <Button type="text" onClick={handleToggleDrawer} className={styles.closeDrawerButton}>
          <img alt="drawer close button" src={CLOSE_ICON} height="24" width="24" />
        </Button>

        {children}
      </Drawer>
    </>
  );
};

export default NavigationDrawer;
